import React from "react";
import { Script } from "gatsby";

import Cursor from "components/cursor";
// import ScrollToTop from "components/scrollToTop";
import LoadingScreen from "components/Loading-Screen";

import "styles/main.css";
import "styles/preloader.css";  

const DarkTheme = ({ children }) => {
  

  return (
    <>
      <Cursor />
      {/* <LoadingScreen /> */}
      { children }
      {/* <ScrollToTop /> */}

      <Script src="/js/wowInit.js"></Script>
    </>
  );
};

export default DarkTheme;
