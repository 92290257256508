import React, { useState } from "react";
import { Link, graphql, useStaticQuery } from "gatsby";
import initIsotope from "common/initIsotope";
import Slider from "react-slick";


const data = [
  {
    id:1,
    title:'Infratech ssOAlert',
    subtitle:'',
    img:'/img/portfolio/freelancer/1.png',
    description:"In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available.",
    category:"Website Landing Page",
    techStack: "Wordpress",
    tags:[""]
  },
  {
    id:2,
    title:'Infratech ssOAlert',
    subtitle:'',
    img:'/img/portfolio/freelancer/2.png',
    description:"",
    category:"",
    techStack: "",
    tags:[]
  },
  {
    id:3,
    title:'Infratech ssOAlert',
    subtitle:'',
    img:'/img/portfolio/freelancer/3.png',
    description:"",
    category:"",
    techStack: "",
    tags:[]
  },
  {
    id:4,
    title:'Infratech ssOAlert',
    subtitle:'',
    img:'/img/portfolio/freelancer/4.png',
    description:"",
    category:"",
    techStack: "",
    tags:[]
  },
  {
    id:5,
    title:'Infratech ssOAlert',
    subtitle:'',
    img:'/img/portfolio/freelancer/5.png',
    description:"",
    techStack: "",
    tags:[]
  },
  {
    id:6,
    title:'Infratech ssOAlert',
    subtitle:'',
    img:'/img/portfolio/freelancer/6.png',
    description:"",
    category:"",
    techStack: "",
    tags:[]
  },
]

const WorksStyle4 = () => {

  const [modalShow, setModalShow] = useState(false)
  const [currentWork, setCurrentWork] = useState()
  const [workSlider, setWorkSlider] = useState({
    nav1:null,
    nav2:null
  })

  React.useEffect(() => {
    setTimeout(() => {
      initIsotope();
    }, 1000);
  }, []);

  const graphdata = useStaticQuery(graphql`
  query MyQuery {
    allSupabaseProject {
      edges {
        node {
          category
          description
          gallery
          id
          image
          name
          subtitle
          tags
        }
      }
    }
  }
`);


  const projects = graphdata?.allSupabaseProject?.edges

  return (
    <section className="portfolio-frl section-padding pb-70" id="portfolio">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8 col-md-10">
            <div className="sec-head  text-center">
              <h6 className="wow fadeIn" data-wow-delay=".5s">
                Portfolio
              </h6>
              <h3 className="wow color-font">
                My Recent Projects
              </h3>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row gallery full-width">
          {/* <div className="filtering col-12">
            <div className="filter wow fadeIn" data-wow-delay=".5s">
              <span data-filter="*" className="active">
                All
              </span>
              <span data-filter=".brand">Branding</span>
              <span data-filter=".web">Mobile App</span>
              <span data-filter=".graphic">Creative</span>
            </div>
          </div> */}

         
            {projects.map((el,i) => {
return (


            <div
              className="col-md-6 items wow fadeIn lg-mr"
              data-wow-delay=".5s"

              key={i}
            >
              <div className="item-img">
                <div className="cont">
                  <h6>{el?.node?.name}</h6>
                  <p>{el?.node?.subtitle}</p>
                </div>
                <Link to={`/portfolio/${el?.node?.id}`} state={{targetElement:null}} className="rota">
                  <div className="portfolio-screen">
                  <img src={el?.node?.image} alt="image" />
                  <div className="item-img-overlay"></div>

                  </div>
                </Link>
                <div className="tags">
                  {el.node && JSON.parse(el?.node?.tags).map((tl,i) => {
                    return (
                  <span key={i}>
                    <Link to="#">{tl}</Link>
                  </span>

                    )

                  })}
                  
                </div>
              </div>
            </div>
)
            })}

         
        
        </div>
      </div>
      
    


    </section>
  );
};

export default WorksStyle4;
