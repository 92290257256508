import React from "react";
import { Link } from "gatsby";
import AboutUs1Date from "../../data/sections/about-us1.json";

const experiences = [
 
  {
    designation:'Full Stack Developer',
    company: 'Fission Tech',
    toDate:'Oct 2022',
    fromDate:'Present',
    duties:["As a key member of the Fission Tech team, I actively participate in the development and enhancement of cutting-edge web applications. Leveraging my expertise in ReactJS, AngularJS, WordPress, and NestJS, I collaborate closely with cross-functional teams to deliver high-quality solutions that align with clients' goals and needs.",
  "Develop and maintain responsive and interactive web applications using ReactJS and AngularJS.",
  "Collaborate with design teams to transform wireframes and mockups into seamless user interfaces.",
  "Customize and optimize WordPress websites to ensure exceptional performance and functionality.",
  "Architect and implement server-side applications using NestJS to enhance backend capabilities",
  "Work closely with project managers and clients to understand requirements and provide technical insights.",
  "Stay updated with the latest industry trends and best practices to ensure the incorporation of innovative solutions into projects"
  ],

  },
  {
    designation:'Frontend Developer',
    company: 'Designer Dev',
    toDate:'DEC 2020',
    fromDate:'JULY 2022',
    duties:["Maintained complex technology infrastructure and collaborated with product team to implement new features and strategically plan for future products.","Develop and maintain responsive and interactive web applications using ReactJS and AngularJS.",
  "Used JavaScript, SQL, and HTML to develop app solutions. Consulted with engineering team members to determine system loads and develop improvement point.",
  "Reviewed code, debugged problems and corrected issues.",
  "Developed clear specifications for projects plans using client requirements.",
  "Convert Figma and AdobeXD designs to HTML/Css, React and Nextjs."
  ],

  },
  {
    designation:'Freelance Web Developer',
    company: '',
    toDate:'DEC 2020',
    fromDate:'Present',
    duties:[
     "Planned website development, converting mockups into usable web presence with HTML, JavaScript, AJAX and JSON coding.",
     "Implemented changes and integrated requested elements to streamline business operations",
     "Coded websites using React JS, Next JS, Gatsby JS, Redux, JavaScript and Typescript. Built and styled new mobile-friendly websites, transitioning legacy presentations to simultaneous easy-to-use versions."
  ],

  },
  {
    designation:'Web Developer Intern',
    company: 'Designer Dev',
    toDate:'JUNE 2020',
    fromDate:'DEC 2020',
    duties:[
     "During my six-month internship at Panacloud, I had the valuable opportunity to gain hands-on experience in web development. Immersed in a dynamic learning environment, I honed my skills in HTML, CSS, JavaScript, and ReactJS while contributing to real-world projects",
     "Collaborated with senior developers to assist in the creation of interactive and user-friendly web applications",
     "Translated design concepts into functional HTML and CSS code, ensuring pixel-perfect implementations.",
     "Developed front-end features using JavaScript, enhancing the user experience and overall functionality"
  ],

  },

]

const AboutUs = () => {
  return (
    <>
    
    <section className="about-us section-padding" id="about">
      <div className="container">
        <div className="row justify-content-between">
          <div className="col-lg-5 valign md-mb50">
            <div className="mb-50">
              <h3 className="fw-600 text-u ls1 mb-30 color-font text-center text-lg-left">
                {/* {AboutUs1Date.title} */}
                About me
              </h3>
              <p>
                {/* {AboutUs1Date.content} */}
                Welcome to my digital realm, where innovation converges with
                code and technology transforms possibilities into realities. As
                a seasoned software developer freelancer, I specialize in
                crafting bespoke solutions that cater to your unique business
                needs. Whether you're a startup seeking a custom application or
                an established enterprise in pursuit of technical excellence,
                I'm here to architect the digital tools that drive your success.
              </p>

              {/* <Link to="/about/about-dark" className="butn bord curve mt-30">
                <span>Read More</span>
              </Link> */}
            </div>
          </div>
          <div className="col-lg-5 mb-50">
            <h3 className="fw-600 text-u ls1 mb-30 color-font text-center text-lg-left">
              {/* {AboutUs1Date.smallTitle} */}
              Education
            </h3>
            <ul className="c-list">
              <li>
                <h5>Matriculation</h5>
                <div className="education-meta">
                  <p>Karachi Folk's High School</p>
                  <span>-</span>
                  <p>2016-09</p>
                </div>
              </li>
              <li>
                <h5>Intermediate</h5>
                <div className="education-meta">
                  <p>PECHS Government Degree Science College</p>
                  <span>-</span>
                  <p>2018-10</p>
                </div>
              </li>
              <li>
                <h5>Cloud Computing</h5>
                <div className="education-meta">
                  <p>PIAIC</p>
                  <span>-</span>
                  <p>Ongoing</p>
                </div>
              </li>
            </ul>
          </div>
          {/* <div className="col-lg-7 img">
            <img src={AboutUs1Date.image} alt="" />
            <div className="stauts">
              {AboutUs1Date.stauts.map((item) => (
                <div className="item" key={item.id}>
                  <h4>
                   4<span>{item.letter}</span>
                  </h4>
                  <h6>{item.stautsName}</h6>
                </div>
              ))}
            </div>
          </div> */}
        </div>
      </div>
    </section>
    <section className="experience" id="experience">
<div className="container">
        <div className="row justify-content-between">
          <div className="col-lg-12 mb-30">
            <h3 className="fw-600 text-u ls1 color-font text-center text-lg-left">
              {/* {AboutUs1Date.title} */}
              Experience
            </h3>
          </div>
          {experiences.map((el,i) => {
            return (

          <div className="col-lg-6 mb-50 exp-line" key={i}>
            
              <ul className="c-list">
                <li>
                  <h5>{el.designation}</h5>
                  <div className="experience-meta">
                    <p>{el.company}</p>
                    <span>-</span>
                    <p>{el.toDate} - {el.fromDate}</p>
                  </div>
                  <ul className="duty-list">
                    {el.duties.map((dl,i) => {
                      return (
                        
                    <li key={i}>
                      {dl}
                    </li>
                      )
                    })}
                   
                  </ul>
                </li>
                
               
              </ul>
             
          </div>
            )
          })}
          {/* <div className="col-lg-6 md-mb50">
            
              <ul className="c-list">
              <li>
                  <h5>Freelance Web Developer</h5>
                  <div className="experience-meta">
                    <p>Fiver</p>
                    <span>-</span>
                    <p>DEC 2020 - Present</p>
                  </div>
                  <ul className="duty-list">
                    <li>
                      Planned website development, converting mockups into
                      usable web presence with HTML, JavaScript, AJAX and JSON
                      coding.
                    </li>
                    <li>
                      Implemented changes and integrated requested elements to
                      streamline business operations
                    </li>
                    <li>
                      Coded websites using React JS, Next JS, Gatsby JS, Redux,
                      JavaScript and Typescript. Built and styled new
                      mobile-friendly websites, transitioning legacy
                      presentations to simultaneous easy-to-use versions.
                    </li>
                  </ul>
                </li>
              
                <li>
                  <h5>Web Developer Intern</h5>
                  <div className="experience-meta">
                    <p>Panacloud</p>
                    <span>-</span>
                    <p>JUNE 2020 - DEC 2020</p>
                  </div>
                  <ul className="duty-list">
                    <li>
                      During my six-month internship at Panacloud, I had the
                      valuable opportunity to gain hands-on experience in web
                      development. Immersed in a dynamic learning environment, I
                      honed my skills in HTML, CSS, JavaScript, and ReactJS
                      while contributing to real-world projects
                    </li>
                    <li>
                      Collaborated with senior developers to assist in the
                      creation of interactive and user-friendly web applications
                    </li>
                    <li>
                      Translated design concepts into functional HTML and CSS
                      code, ensuring pixel-perfect implementations.
                    </li>
                    <li>
                      Developed front-end features using JavaScript, enhancing
                      the user experience and overall functionality
                    </li>
                  </ul>
                </li>
              </ul>
           
          </div> */}

          {/* <div className="col-lg-7 img">
            <img src={AboutUs1Date.image} alt="" />
            <div className="stauts">
              {AboutUs1Date.stauts.map((item) => (
                <div className="item" key={item.id}>
                  <h4>
                   4<span>{item.letter}</span>
                  </h4>
                  <h6>{item.stautsName}</h6>
                </div>
              ))}
            </div>
          </div> */}
        </div>

</div>
    </section>

    </>

  );
};

export default AboutUs;
