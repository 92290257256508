import React, { Component } from "react";
import Slider from "react-slick";

const CertificationSlider = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-12 mb-50">
          <div className="sec-head">
          <h3 className="wow color-font animated text-center mb-30">Certifications</h3>

          </div>
          <Slider {...settings}>
            <div>
              <img
                src="/img/certifications/css.png"
                alt=""
                style={{ width: "100%", maxWidth: "800px", margin: " 0 auto" }}
              />
            </div>
            <div>
            <img
                src="/img/certifications/javascript_basic.png"
                alt=""
                style={{ width: "100%", maxWidth: "800px", margin: " 0 auto" }}
              />
            </div>
            <div>
            <img
                src="/img/certifications/javascript_intermediate.png"
                alt=""
                style={{ width: "100%", maxWidth: "800px", margin: " 0 auto" }}
              />
            </div>
            <div>
            <img
                src="/img/certifications/sql_basic.png"
                alt=""
                style={{ width: "100%", maxWidth: "800px", margin: " 0 auto" }}
              />
            </div>
            <div>
            <img
                src="/img/certifications/sql_intermediate.png"
                alt=""
                style={{ width: "100%", maxWidth: "800px", margin: " 0 auto" }}
              />
            </div>
            <div>
            <img
                src="/img/certifications/udemy.png"
                alt=""
                style={{ width: "100%", maxWidth: "800px", margin: " 0 auto" }}
              />
            </div>
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default CertificationSlider;
