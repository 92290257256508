import React, { useCallback, useLayoutEffect } from "react";
import Navbar from "components/Navbar/navbar";
import Footer from "components/Footer/footer";
import DarkTheme from "layouts/Dark";
import initIsotope from "common/initIsotope";
import FreelancreIntro from "components/Freelancre-intro/freelancre-intro";
import AboutUs from "components/About-us/about-us";
import Services5 from "components/Services5/services5";
import WorksStyle4 from "components/Works-style4/works-style4";
import CertificationSlider from "components/slider";
import SContactForm from "components/s-contact-form/s-contact-form";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";



const Homepage = ({location}) => {
  const [pageLoaded, setPageLoaded] = React.useState(false);

  // React.useEffect(() => {
  //   setPageLoaded(true);
  //   if (pageLoaded) {
  //     window.addEventListener("load", () => {
  //       setTimeout(() => {
  //         if (pageLoaded) initIsotope();
  //         document.querySelector('#particles-js canvas')?.style.removeProperty('position');
  //       }, 500);
  //     });
  //   }

  //   setTimeout(() => {
  //     initIsotope();
  //     document.querySelector('#particles-js canvas')?.style.removeProperty('position');
  //   }, 500);

  // }, [pageLoaded]);

  // const particlesInit = useCallback(async (engine) => {
  //   await loadFull(engine);
  // }, []);

  const navbarRef = React.useRef(null);
  const logoRef = React.useRef(null);

  React.useEffect(() => {
    var navbar = navbarRef.current;

    if (window.pageYOffset > 300) {
      navbar.classList.add("nav-scroll");
    } else {
      navbar.classList.remove("nav-scroll");
    }

    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        navbar.classList.add("nav-scroll");
      } else {
        navbar.classList.remove("nav-scroll");
      }
    });
  }, [navbarRef]);


  const useScrollTo = id => {
      useLayoutEffect(() => {
        if (id) {
          const el = document.getElementById(id)
          const top = el.getBoundingClientRect().height + el.getBoundingClientRect().top
          // const top = 700
          window.scrollTo(0,10000)
        }
      }, [id])
    }

    useScrollTo(location?.state?.targetElement)

  return (
   <>
   <DarkTheme>
      <Navbar nr={navbarRef} lr={logoRef} />
      <FreelancreIntro />
      <AboutUs/>
      <Services5  />
      <WorksStyle4 />
      <CertificationSlider/>
      {/* <AboutUs5 />
      <FullTestimonials showHead />
      <Blogs2 /> */}
      <SContactForm noLine />
      <Footer />
    </DarkTheme>
   </>
  );
};

export const Head = () => {
  return (
    <>
      <title>Jami - Home</title>
      <link rel="stylesheet" href="demo.css" />
    </>
  )
}

export default Homepage;







